import { getRequest, postRequest, gateway, urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 巡检任务
export const inspectionTaskApi = {
  // 巡检任务列表
  queryInspectionTask: (params) => {
    return getRequest(`${gateway}/operationInspectionTask/list`, params);
  },
  // 巡检任务-执行记录明细新增
  createExecuteTask: (params) => {
    return postRequest(`${gateway}/operationInspectionTaskItem/update`, params);
  },
    // 巡检任务-执行记录明细新增
    uploadFile: (params) => {
      return postRequest(`${gateway}/operationInspectionTaskItem/upload`, params);
    },
    
  // 巡检任务列表
  queryInspectionDetails: (id) => {
    return getRequest(`${gateway}/operationInspectionTask/get/${id}`);
  },
  // 巡检单列表
  queryInspectionForm: () => {
    return getRequest(`${urlTitle}/operation/inspection/form/list`);
  },
  // 用户列表
  queryUser: () => {
    return getRequest(`${urlTitle}/user/getUserList`);
  },
  // 创建巡检任务
  createInspectionTask: (params) => {
    return postRequest(`${gateway}/operationInspectionTask/add`, params);
  },
  // 修改状态
  updateTaskStatus: (id, state) => {
    return postRequest(
      `${gateway}/operationInspectionTask/update/${id}/${state}`
    );
  },
  // 修改巡检任务
  updateInspectionTask: (params) => {
    return postRequest(`${gateway}/operationInspectionTask/update`, params);
  },
  // 删除巡检任务
  deleteInspectionTask: (id) => {
    return postRequest(`${gateway}/operationInspectionTask/${id}`);
  },
    // 巡检单列表带场站id搜索
    queryInspectionFormParams: (params) => {
      return getRequest(`${urlTitle}/operation/inspection/form/list`,params);
    },
};
