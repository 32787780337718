<!-- 巡检任务 -->
<template>
  <div id="inspection-task">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>巡检任务</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="变配电站">
              <el-select :popper-append-to-body="false" v-model="requestParam.stationId" placeholder="请选择场站名称"
                clearable>
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                  :value="item.id"></el-option>
              </el-select> 
            </el-form-item> -->
            <el-form-item label="变配电站">
              <el-input v-model.trim="requestParam.stationName" placeholder="请输入变配电站" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务标题">
              <el-input v-model.trim="requestParam.taskName" placeholder="请输入任务标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务编号">
              <el-input v-model.trim="requestParam.taskNum" placeholder="请输入任务编号" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-select :popper-append-to-body="false" v-model="requestParam.state" placeholder="请选择" clearable>
                <el-option label="保存" value="1"></el-option>
                <el-option label="待执行" value="2"></el-option>
                <el-option label="执行中" value="3"></el-option>
                <el-option label="完成" value="4"></el-option>
                <el-option label="终止" value="5"></el-option>
              </el-select>  
            </el-form-item>
            <el-form-item label="运维负责人">
              <el-input v-model.trim="requestParam.principalName" placeholder="请输入负责人名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="运维执行人">
              <el-input v-model.trim="requestParam.operationUserName" placeholder="请输入执行人名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="计划开始时间" class="query-date">
              <el-date-picker v-model="requestParam.planStartAtStart" :append-to-body="false" type="date"
                placeholder="请选择计划开始时间" value-format="yyyy-MM-dd" @change="checkDate"></el-date-picker>
              <!-- <span> ~ </span>
              <el-date-picker v-model="requestParam.planStartAtEnd" :append-to-body="false" type="date"
                placeholder="请选择计划完成时间" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker> -->
            </el-form-item>
            <el-form-item label="计划结束时间" class="query-date">
              <el-date-picker v-model="requestParam.planStartAtEnd" :append-to-body="false" type="date"
                placeholder="请选择计划结束时间" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker>
            </el-form-item>
            <el-form-item label="实际开始时间" class="query-date">
              <el-date-picker v-model="requestParam.startAtStart" :append-to-body="false" type="date"
                placeholder="请选择实际开始时间" value-format="yyyy-MM-dd" @change="checkDate"></el-date-picker>
              <!-- <span> ~ </span>
              <el-date-picker v-model="requestParam.startAtEnd" :append-to-body="false" type="date"
                placeholder="请选择实际完成时间" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker> -->
            </el-form-item>
            <el-form-item label="实际结束时间" class="query-date">
              <el-date-picker v-model="requestParam.startAtEnd" :append-to-body="false" type="date"
                placeholder="请选择结束完成时间" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button v-if="isButtonPower('createButtona')" type="primary" icon="el-icon-s-order"
              @click="createDialog">任务发布</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stationName" label="变配电站" width="200">
          </el-table-column>
          <el-table-column align="center" prop="stationNumber" label="场站编码" width="150">
          </el-table-column>
          <el-table-column align="center" prop="companyName" label="运维单位" width="200">
          </el-table-column>
          <el-table-column align="center" prop="taskNum" label="巡检任务编号" width="150">
          </el-table-column>
          <el-table-column align="center" prop="taskName" label="任务标题" width="200">
          </el-table-column>
          <el-table-column align="center" prop="stateDict" label="任务状态" width="100">
            <template slot-scope="scope">
              <div v-show="scope.row.stateDict === '保存'" style="color: #aed6ff">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '待执行'" style="color: #66b1ff">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '执行中'" style="color: yellow">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '完成'" style="color: #67c23a">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '终止'" style="color: red">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '通过'" style="color: #0dbc79">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '驳回'" style="color: #d12018">
                {{ scope.row.stateDict }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="operationFormNum" label="巡检单编号" width="150">
          </el-table-column>
          <el-table-column align="center" prop="operationFormName" label="巡检单名称" width="150">
          </el-table-column>
          <el-table-column align="center" prop="itemSum" label="巡检项数" width="100">
          </el-table-column>
          <el-table-column align="center" prop="resultNum" label="缺陷数量" width="100">
          </el-table-column>
          <el-table-column align="center" prop="principalName" label="运维负责人" width="150">
          </el-table-column>
          <el-table-column align="center" prop="operationUserName" label="运维执行人" width="150">
          </el-table-column>
          <el-table-column align="center" prop="createName" label="创建人" width="150">
          </el-table-column>
          <el-table-column align="center" prop="planStartTime" label="任务计划开始时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="planEndTime" label="任务计划结束时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="任务实际开始时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="endTime" label="任务实际结束时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="subTime" label="提交时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注" width="150">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate" width="250" fixed="right">
            <template slot-scope="scope">
              <div class="operate">
                <div class="flex-row" v-show="scope.row.state === 1">
                  <div v-if="isButtonPower('submitButtona')">
                    <el-button type="text" @click="submitDialog(scope.row)">提交</el-button>
                    <span>|</span>
                  </div>
                  <div v-if="isButtonPower('submitButtona')">
                    <el-button type="text" @click="updateDialog(scope.row)">编辑</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div v-show="scope.row.state === 2">
                  <div v-if="isButtonPower('executeButtona')">
                    <el-button type="text" @click="statusDialog(scope.row, 3)">执行</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div v-show="scope.row.state === 3">
                  <div v-if="isButtonPower('completeButtona')">
                    <el-button type="text" @click="completeDialog(scope.row, 4)">完成</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div v-show="scope.row.state === 2 || scope.row.state === 3">
                  <div v-if="isButtonPower('stopButtona')">
                    <el-button type="text" @click="statusDialog(scope.row, 5)">终止</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div>
                  <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                  <span>|</span>
                </div>
                <div v-if="isButtonPower('deleteButtona')">
                  <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="查看详情" :visible.sync="viewVisible" width="800px" @close="dialogClose('taskForm')">
      <div class="dialog-content">
        <div class="details-title">
          <div class="square"></div>
          <div>任务明细</div>
        </div>
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="detailsData">
          <el-form-item label="场站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="巡检单名称">
            <el-input v-model="detailsData.operationFormName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="巡检单编号">
            <el-input v-model="detailsData.operationFormNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维负责人">
            <el-input v-model="detailsData.principalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUserName" :readonly="true"></el-input>
          </el-form-item>
          <!-- <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item> -->
          <el-form-item label="计划开始时间">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间" class="">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际结束时间" class="">
            <el-input v-model="detailsData.endTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.subTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="3" v-model="detailsData.remark" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item v-if="pictureUrl" label="图片">
            <el-image style="width: 200px; height: 98px" :src="pictureUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
          </el-form-item>
          <div class="" v-if="detailsData.state== 3||detailsData.state== 4||detailsData.state== 5">
            <div class="interval"></div>
            <div class="details-title" >
              <div class="square"></div>
              <div>执行结果</div>
            </div>
            <el-form-item class="short-form" label="巡检项数量">
              <el-input v-model="detailsData.itemNum" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item class="short-form" label="正常数量">
              <el-input v-model="detailsData.accomplishNum" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item class="short-form" label="异常数量">
              <el-input v-model="detailsData.warningNum" :readonly="true"></el-input>
            </el-form-item>
          </div>
          <div class="interval"></div>
          <div class="details-title">
            <div class="square"></div>
            <div>巡检项内容</div>
          </div>
          <div v-show="selectInspectionTask.length === 0" class="no-data">
            暂无数据
          </div>
          <el-form-item v-show="selectInspectionTask.length !== 0" v-for="(item, index) in selectInspectionTask"
            :key="index">
            <div class="select">
              <div class="select-title" :class="item.showStatus ? 'selected-inspection' : 'default-inspection'
                " @click="selectChange(item)">
                <span>{{ item.inspectionName }}</span>
                <div><i class="el-icon-arrow-down"></i></div>
              </div>
              <div v-show="item.showStatus &&
                item.operationInspectionTaskItems.length !== 0
                " class="select-content" v-for="(element, key) in item.operationInspectionTaskItems" :key="key">
                <span>{{ element.inspectionItemName }}</span>
                <div class="inspection-result" v-if="element.completeResult.disabled">
                  <el-form-item label="巡检状态">
                    <el-input v-model="element.completeResult.itemStatusDict" :readonly="true"></el-input>
                  </el-form-item>
                  <el-form-item label="处理状态" v-if="element.completeResult.itemStatus === 2">
                    <el-input v-model="element.completeResult.resultStatusDict" :readonly="true"></el-input>
                  </el-form-item>
                  <el-form-item label="备注" v-if="element.completeResult.itemStatus === 2">
                    <el-input type="textarea" v-model="element.completeResult.remark" :rows="3"
                      :readonly="true"></el-input>
                  </el-form-item>

                  <el-form-item label="图片"
                    v-if="element.completeResult.itemStatus === 2 && element.completeResult.pictureUrl">
                    <el-image style="width: 200px; height: 98px" :src="element.completeResult.pictureUrl" fit="cover"
                      :preview-src-list="previewList"></el-image>
                  </el-form-item>
                </div>
              </div>
              <div v-show="item.showStatus &&
                item.operationInspectionTaskItems.length === 0
                " class="no-data">
                暂无数据
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建巡检任务' : '修改巡检任务'" :visible.sync="dialogVisible" width="840px"
      :rules="rules" @close="dialogClose('taskForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="taskForm" :rules="rules">
          <el-form-item label="变配电站" prop="stationId">
            <el-input style="width: 330px" placeholder="请选择变配电站" v-model="stationName" :readonly="true">
              <template slot="append">
                <span @click="selectStation">选择配电站</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="巡检单" prop="operationInspectionFormId">
                <el-select :popper-append-to-body="false" v-model="taskForm.operationInspectionFormId"
                  placeholder="请选择巡检单">
                  <el-option v-for="item in inspectionForm" :key="item.id" :label="item.operationFormName"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <div class=""></div>
          <el-form-item label="任务标题" prop="taskName">
            <el-input class="" placeholder="请输入任务标题" v-model.trim="taskForm.taskName" maxlength="20">
            </el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="运维单位" prop="companyId">
            <el-select :popper-append-to-body="false" clearable v-model="taskForm.companyId" placeholder="请选择运维单位"
              @change="changeObtainUser">
              <el-option v-for="item in ComList" :key="item.companyId" :label="item.companyName"
                :value="item.companyId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位负责人">
            <!-- <el-select :popper-append-to-body="false" v-model="taskForm.principalId" placeholder="请选择负责人">
              <el-option v-for="item in userData" :key="item.id" :label="item.userName" :value="item.id"></el-option>
            </el-select> -->
            <el-input placeholder="请输入单位负责人" :readonly="true" clearable v-model.trim="approverName"></el-input>
          </el-form-item>
          <el-form-item label="运维负责人" prop="principalId">
            <el-select :popper-append-to-body="false" v-model="taskForm.principalId" placeholder="请选择运维负责人">
              <el-option v-for="item in yunweiUserData" :key="item.userId" :label="item.userName" :value="item.userId"></el-option>
            </el-select>
            <!-- <el-input placeholder="请输入运维负责人" :readonly="true" clearable v-model.trim="approverName"></el-input> -->
          </el-form-item>
          <el-form-item label="运维执行人" prop="operationUserId">
            <el-select :popper-append-to-body="false" v-model="taskForm.operationUserId" placeholder="请选择运维执行人">
              <el-option v-for="item in userData" :key="item.userId" :label="item.userName"
                :value="item.userId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="long-label" label="计划开始时间" prop="planStartTime">
            <el-date-picker v-model="taskForm.planStartTime" type="datetime" :picker-options="pickerOptionsStart"
              placeholder="请选择计划开始时间" value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false"
              @change="checkDateTime"></el-date-picker>
          </el-form-item>

          <el-form-item class="long-label" label="计划完成时间" prop="planEndTime">
            <el-date-picker v-model="taskForm.planEndTime" type="datetime" placeholder="请选择计划完成时间"
              value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false" :picker-options="pickerOptionsEnd"
              @change="checkDateTime"></el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input placeholder="请输入备注信息" type="textarea" :rows="3" v-model.trim="taskForm.remark"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload action="" :auto-upload="false" :limit="1" accept="image/*" :file-list="fileList"
              :on-change="pictureChange" list-type="picture" :on-remove="handleRemove">
              <el-button size="small" type="primary">点击上传</el-button>
              <!-- <i class="el-icon-plus"></i> -->
              <span slot="tip" class="el-upload-tip">
                只能上传jpg/png文件,且不超过5M,建议分辨率900*400
              </span>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeStatus('taskForm', 1)">保存</el-button>
        <el-button type="primary" @click="changeStatus('taskForm', 2)">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteInspectionTask">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="submitVisible" width="436px">
      <div class="dialog-text">任务一旦提交将无法修改,请确认</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="submitVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitConfirm('taskForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="statusType === 3 ? '执行中' : '终止'" :visible.sync="statusVisible" width="431px">
 
      <div class="dialog-text">
        {{
            statusType === 3
              ? "确认执行该任务。"
              : "确定终止该任务。"
          }}
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="statusVisible = false">取 消</el-button>
        <el-button type="primary" @click="statusConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="执行结果" :visible.sync="completeVisible" width="800px" @close="completeClose('statusForm')">
      <div class="dialog-content">
        <div class="complete-title">任务明细</div>
        <el-form class="dialog-form" :inline="true">
          <el-form-item label="场站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="巡检单名称">
            <el-input v-model="detailsData.operationFormName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="巡检单编号">
            <el-input v-model="detailsData.operationFormNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维负责人">
            <el-input v-model="detailsData.principalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUserName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <!-- <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item> -->
          <el-form-item label="计划开始时间">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间" class="">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际结束时间" class="">
            <el-input v-model="detailsData.endTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.subTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="3" v-model="detailsData.remark" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item v-if="pictureUrl" label="图片">
            <el-image style="width: 200px; height: 98px" :src="pictureUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
          </el-form-item>
          <div class="interval"></div>
          <div class="complete-title">巡检项内容</div>
          <div v-show="selectInspectionTask.length === 0" class="no-data">
            暂无数据
          </div>
          <el-form-item v-show="selectInspectionTask.length !== 0" v-for="(item, index) in selectInspectionTask"
            :key="index">
            <div class="select">
              <div class="select-title" :class="item.showStatus ? 'selected-inspection' : 'default-inspection'" 
              @click="selectChange(item)">
                <span>{{ item.inspectionName }} </span>

                <div><i class="el-icon-arrow-down"></i></div>
              </div>
              <div v-show=" item.showStatus &&
                item.operationInspectionTaskItems.length !== 0 || item.id==showId" 
                class="select-content" v-for="(element, key) in item.operationInspectionTaskItems" :key="key">
                <div>
                  <div class="circle"></div>
                  <span>{{ element.inspectionItemName }} <span style="font-size: 16px;" v-if="element.completeResult.resultStatus" class=""><i class="el-icon-circle-check"></i></span></span>
                </div>

                <div class="inspection-result">
                  <el-form class="dialog-form" ref="statusForm" :model="element.completeResult" :inline="true"
                    :show-message="false">
                    <el-form-item label="巡检状态" prop="itemStatus" :rules="formRules.itemStatus">
                      <el-select :popper-append-to-body="false" v-model="element.completeResult.itemStatus"
                        :disabled="element.completeResult.disabled" placeholder="请选择" clearable>
                        <el-option label="正常" :value="1"></el-option>
                        <el-option label="异常" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="处理状态" v-if="element.completeResult.itemStatus === 2" prop="resultStatus"
                      :rules="formRules.resultStatus">
                      <el-select :popper-append-to-body="false" v-model="element.completeResult.resultStatus"
                        placeholder="请选择" :disabled="element.completeResult.disabled" clearable>
                        <el-option label="处理" :value="1"></el-option>
                        <el-option label="未处理" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="备注" v-if="element.completeResult.itemStatus === 2">
                      <el-input placeholder="请输入备注信息" type="textarea" :rows="3"
                        v-model.trim="element.completeResult.remark"></el-input>
                    </el-form-item>

                    <el-form-item label="图片" v-if="element.completeResult.itemStatus === 2">
                      <el-upload action="" :auto-upload="false" :limit="1" accept="image/*"
                        :on-remove="(file, fileList) => { deleteImg(file, fileList, element) }"
                        :file-list="element.completeResult.file" :on-change="taskChange" list-type="picture">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <!-- <i class="el-icon-plus"></i> -->
                        <span slot="tip" class="el-upload-tip">
                          只能上传jpg/png文件,且不超过5M,建议分辨率900*400
                        </span>
                      </el-upload>
                    </el-form-item>
                  </el-form>

                  <el-button v-if="element.completeResult.completeStatus === 0 ||
                    !element.completeResult.disabled
                  " class="abnormal-button" size="medium" type="primary" @click="abnormal(element,item)">保存</el-button>
                </div>
                <!-- <div class="line"></div> -->
              </div>
              <div v-show="item.showStatus &&
                item.operationInspectionTaskItems.length === 0
                " class="no-data">
                暂无数据
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="completeVisible = false">取 消</el-button>
        <el-button type="primary" @click="completeConfirm">完 成</el-button>
      </div>
    </el-dialog>
    <!-- 变配电站选择 -->
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationName"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>
<script>
import { inspectionTaskApi } from "@/api/inspectionTask.js";
import { inspectionFormApi } from "@/api/inspectionForm.js";
import stationDialog from "./commponents/stationDialog.vue";
import { getStaComList, deleteSysFile } from "@/api/testTask.js";
export default {
  name: "",
  components: { stationDialog },
  data() {
    const mustStation = (rule, value, callback) => {
      if (!this.stationName) {
        callback("请选择变配电站");
      }
      callback();
    };
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        stationId: "",
        startAtStart: null,
        startAtEnd: null,
        operationUserName: "",
        principalName: "",
        state: "",
        taskName: '',
        taskNum: '',
        planStartAtEnd: null,
        planStartAtStart: null,
        stationName: '',
      },
      total: 0,
      inputValue: 1,
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      dialogStatus: false,
      submitVisible: false,
      executeVisible: false,
      statusVisible: false,
      completeVisible: false,
      requestType: -1,
      detailsData: {},
      taskForm: {
        stationId: "",
        taskName: "",
        operationInspectionFormId: "",
        planStartTime: "",
        planEndTime: "",
        operationUserId: "",
        remark: "",
        principalId: "",
        companyId: '',
      },
      defaultData: {
        stationId: "",
        taskName: "",
        operationInspectionFormId: "",
        planStartTime: "",
        planEndTime: "",
        operationUserId: "",
        remark: "",
        principalId: "",
      },
      id: "",
      rules: {
        taskName: [
          { required: true, message: "请输入任务标题", trigger: "blur" },
        ],
        companyId: [
          { required: true, message: "请选择运维单位", trigger: "change" },
        ],
        operationInspectionFormId: [
          { required: true, message: "请选择巡检单", trigger: "change" },
        ],
        planStartTime: [
          { required: true, message: "请选择计划开始时间", trigger: "change" },
        ],
        planEndTime: [
          { required: true, message: "请选择计划完成时间", trigger: "change" },
        ],
        operationUserId: [
          { required: true, message: "请选择执行人", trigger: "change" },
        ],
        principalId: [
          { required: true, message: "请选择运维负责人", trigger: "change" },
        ],
        // stationId: [
        //   { required: true, validator: mustStation, trigger: "change" },
        // ],
        stationId: [
          { required: true, message: "请选择变配电站", trigger: "change" },
        ],
      },
      completeResult: {},
      stationId: 0,
      fileList: [],
      file: [],
      taskFileList: [],
      taskFile: [],
      pictureUrl: "",
      previewList: [],
      inspectionForm: [],
      userData: [],
      yunweiUserData:[],
      stationName: "",
      stationData: [],
      selectInspectionTask: [],
      statusType: 0,
      allButtonPower: [],
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      formRules: {
        itemStatus: [
          {
            required: true,
            message: "请选择巡检状态",
            trigger: "change",
          },
        ],
        resultStatus: [
          {
            required: true,
            message: "请选择处理状态",
            trigger: "change",
          },
        ],
      },
      approverName: '',
      ComList: [],
      showId:'',
    };
  },

  created() {
    this.$store.commit("increment", "运维管理");
    this.$store.commit("selectChild", "巡检任务");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryInspectionTask();
    // this.queryInspectionFormParams();
    // this.queryUser();
    this.queryStationName();
    this.allButtonPower = sessionStorage.getItem('roleInfo');
  },

  methods: {
    //获取运维单位列表
    gatComList() {
      getStaComList(this.taskForm.stationId).then(res => {
        this.ComList = res.data
        if (this.taskForm.companyId) {
          this.obtainUser(this.taskForm.companyId)
        }
      })
    },
    //选择运维单位
    changeObtainUser(value) {
      console.log(value);
      let obj = this.ComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      //contactNameId
      // this.taskForm.principalId = obj.contactNameId
      this.approverName = obj.contactName
      this.userData = obj.comUserList
      this.yunweiUserData = obj.fuzerenUserList
      if (this.taskForm.operationUserId) {  
        this.taskForm.operationUserId = null
      }
      if (this.taskForm.principalId) {
        this.taskForm.principalId = null
      }
    },
    //选择运维单位
    obtainUser(value) {
      console.log('1111', value);
      let obj = this.ComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      //contactNameId
      // this.taskForm.principalId = obj.contactNameId
      this.approverName = obj.contactName
      this.userData = obj.comUserList
      this.yunweiUserData = obj.fuzerenUserList
    },
    getStationName(value) {
      if (value.id) {
        this.taskForm.stationId = value.id;
        this.stationName = value.stationName;
        this.taskForm.companyId = ''
        this.taskForm.operationUserId = ''
        this.approverName = ''
        this.approverId = ''
        this.gatComList()
        this.queryInspectionFormParams()
        this.dialogStatus = false;
      }
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    changeDialog(value) {
      this.dialogStatus = value;
    },
    selectStation() {
      this.dialogStatus = true;
    },
    submitConfirm() {
      console.log(this.requestType);
      if (this.requestType === 0) {
        this.dialogConfirm("taskForm");
      } else {
        this.updateTaskStatus(this.id, 2);
      }
    },
    statusConfirm() {
      this.updateTaskStatus(this.id, this.statusType);
    },
    statusDialog(item, status) {
      this.id = item.id;
      this.statusType = status;
      this.queryInspectionDetails();
      this.statusVisible = true;
    },
    completeDialog(item, status) {
      this.statusType = status;
      this.requestType = status;
      this.id = item.id;
      this.queryInspectionDetails();
      this.completeVisible = true;
    },
    dialogClose(formName) {
      this.stationId = 0;
      this.stationName = "";
      this.fileList = [];
      this.previewList = [];
      this.ComList = [];
      this.inspectionForm = []
      this.approverName = '';
      this.pictureUrl = "";
      this.taskForm = {
        stationId: "",
        taskName: "",
        operationInspectionFormId: "",
        planStartTime: "",
        planEndTime: "",
        operationUserId: "",
        remark: "",
        principalId: "",
        companyId: '',
      }
      // Object.assign(this.taskForm, this.defaultData);
      this.$refs[formName].resetFields();
    },
    completeClose(formName) {
      this.$refs[formName].resetFields();
    },
    pictureChange(file, fileList) {
      this.file = fileList.map((item) => item.raw);
      if (this.file.length !== 0) {
        this.$message({
          message: "图片只能上传一张",
          duration: 3000,
          customClass: "messageText",
        });
      }
    },
    taskChange(file, fileList) {
      // console.log(fileList);
      this.taskFile = fileList.map((item) => item.raw);
      if (this.taskFile.length !== 0) {
        this.$message({
          message: "图片只能上传一张",
          duration: 3000,
          customClass: "messageText",
        });
      }
    },
    deleteImg(file, fileList, element) {
      console.log(file, element);
      if (file.id) {
        deleteSysFile(file.id).then(res => {
          if (res.code == 200) {
            this.$message({
              message: res.message,
              type: 'success',
              duration: 3000,
              customClass: "messageText",
            });
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              duration: 3000,
              customClass: "messageText",
            });
          }
        })
      }
    },
    selectChange(item) {
      item.showStatus = !item.showStatus;
      this.showId=''
      this.$forceUpdate();
    },
    createDialog() {
      this.requestType = 0;
      Object.assign(this.taskForm, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      this.queryInspectionDetails();
      // Object.assign(this.taskForm, data);
      this.stationName = data.stationName;
      this.taskForm.stationId = data.stationId;
      this.taskForm.operationInspectionFormId = data.operationInspectionFormId;
      this.taskForm.taskName = data.taskName;
      this.taskForm.operationUserId = data.operationUserId;
      this.taskForm.principalId = data.principalId;
      this.taskForm.companyId = data.companyId;
      this.taskForm.planStartTime = data.planStartTime;
      this.taskForm.planEndTime = data.planEndTime;
      this.taskForm.remark = data.remark;
      this.gatComList()
      this.queryInspectionFormParams()
      this.dialogVisible = true;
    },
    viewDialog(data) {
      this.id = data.id;
      this.requestType = -1;
      this.queryInspectionDetails();
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    handleRemove(file, fileList) {
      if (fileList.length === 0) {
        this.file = [];
      } else {
        this.file = fileList.map((item) => item.raw);
      }
    },
    //提交弹窗
    submitDialog(item) {
      this.requestType = -2;
      this.id = item.id;
      this.submitVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryInspectionTask();
    },

    checkDate() {
      if (this.requestParam.startAtStart && this.requestParam.startAtEnd) {
        const startTime = new Date(this.requestParam.startAtStart).getTime();
        const endTime = new Date(this.requestParam.startAtEnd).getTime();
        if (startTime > endTime) {
          this.requestParam.startAtEnd = null;
        }
      }
    },
    //获取停电时长
    checkDateTime() {
      if (this.taskForm.planStartTime && this.taskForm.planEndTime) {
        const startTime = new Date(this.taskForm.planStartTime).getTime();
        const endTime = new Date(this.taskForm.planEndTime).getTime();
        if (startTime == endTime) {
          this.$message({
            message: "计划开始时间与计划结束时间不能相同",
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          this.taskForm.planEndTime = null;
        } else if (startTime > endTime) {
          this.$message({
            message: "计划开始时间不能大于计划结束时间",
            type: "warning",
            duration: 3800,
            customClass: "messageText",
          });
          this.taskForm.planEndTime = null;
        }
      }
    },
    completeConfirm() {
      let isComplete = this.selectInspectionTask.every((item) => {
        return item.operationInspectionTaskItems.every(
          (element) => element.itemStatus === 1 || element.itemStatus === 2
        );
      });
      if (isComplete) {
        this.updateTaskStatus(this.id, 4);
      } else {
        this.$message({
          message: "有巡检项未保存",
          type: "warning",
          duration: 3800,
          customClass: "messageText",
        });
      }
    },
    changeStatus(formName, state) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (state === 2) {
            this.taskForm.state = state;
            this.submitVisible = true;
          } else {
            this.taskForm.state = state;
            this.dialogConfirm("taskForm");
          }
        } else {
          return false;
        }
      });
    },
    dialogConfirm(formName) {
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     if (this.requestType === 0) {
      //       this.createInspectionTask();
      //     } else {
      //       this.updateInspectionTask();
      //     }
      //   } else {
      //     return false;
      //   }
      // });
      if (this.requestType === 0) {
        this.createInspectionTask();
      } else {
        this.updateInspectionTask();
      }
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryInspectionTask();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryInspectionTask();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryInspectionTask();
      // this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryInspectionTask();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryInspectionTask();
    },
    abnormal(element,item) {
      this.showId=item.id
      if (!element.completeResult.itemStatus) {
        this.$message({
          message: "请选择巡检状态",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
        return;
      }
      if (element.completeResult.itemStatus === 2) {
        if (!element.completeResult.resultStatus) {
          this.$message({
            message: "请选择处理状态",
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          return;
        }
      }
      if (element.completeResult.itemStatus) {
        let params;
        if (element.completeResult.itemStatus === 2) {
          params = {
            id: element.id,
            operationInspectionId: element.inspectionId,
            operationInspectionItemId: element.operationInspectionItemId,
            operationInspectionTaskId: this.id,
            resultStatus: element.completeResult.resultStatus,
            remark: element.completeResult.remark,
            itemStatus: element.completeResult.itemStatus,
          };
        } else {
          params = {
            id: element.id,
            operationInspectionId: element.inspectionId,
            operationInspectionItemId: element.operationInspectionItemId,
            operationInspectionTaskId: this.id,
            itemStatus: "1",
            resultStatus: 1,
          };
        }
        inspectionTaskApi.createExecuteTask(params).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "保存成功",
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            if (element.completeResult.itemStatus === 2) {
              if (this.taskFile.length !== 0) {
                this.uploadFile(element.id);
              } else {
                this.queryInspectionDetails();
              }
            }
            if (element.completeResult.itemStatus === 1) {
              this.queryInspectionDetails();
            }
          }
        });
      }
    },
    uploadFile(id) {
      let formData = new FormData();
      if (this.taskFile.length !== 0) {
        formData.append("dataId", id);
        formData.append("multipartFiles", this.taskFile[0]);
      }
      inspectionTaskApi.uploadFile(formData).then((res) => {
        if (res.code === 200) {
          this.taskFile = [];
          this.queryInspectionDetails();
        }
      });
    },
    queryInspectionTask() {
      inspectionTaskApi.queryInspectionTask(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    queryUser() {
      inspectionTaskApi.queryUser().then((res) => {
        if (res.code === 200) {
          this.userData = res.data;
        }
      });
    },
    queryStationName() {
      inspectionFormApi.queryStationName().then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    queryInspectionDetails() {
      inspectionTaskApi.queryInspectionDetails(this.id).then((res) => {
        if (res.code === 200) {
          this.detailsData = res.data;
          this.selectInspectionTask = res.data.operationInspectionPojos.map(
            (item) => {
              item.showStatus = false;
              item.operationInspectionTaskItems.forEach((element) => {
                if (element.itemStatus) {
                  if (this.requestType === -1) {
                    element.completeResult = {
                      itemStatus: element.itemStatus,
                      resultStatus: element.resultStatus,
                      remark: element.itemRemark,
                      disabled: true,
                    };
                    element.completeResult.itemStatusDict =
                      element.itemStatus === 1 ? "正常" : "异常";
                    element.completeResult.resultStatusDict =
                      element.resultStatus === 1 ? "处理" : "未处理";
                  } else {
                    // element.completeResult = {
                    //   itemStatus: element.itemStatus,
                    //   resultStatus: element.resultStatus,
                    //   remark: element.itemRemark,
                    //   disabled: false,
                    // };
                    this.$set(element, "completeResult", {
                      completeStatus: 0,
                      itemStatus: element.itemStatus,
                      resultStatus: element.resultStatus,
                      remark: element.itemRemark,
                      disabled: false,
                      file: [],
                    });
                  }

                  if (element.sysFile.length !== 0) {
                    element.completeResult.file = element.sysFile.map(
                      (item) => {
                        return { url: item.url, id: item.id, };
                      }
                    );
                    if (this.requestType === -1) {
                      element.completeResult.pictureUrl =
                        element.sysFile[0].url;
                      this.previewList.push(element.completeResultpictureUrl);
                    }
                  }
                } else {
                  this.$set(element, "completeResult", {
                    completeStatus: 0,
                    file: [],
                  });
                }
              });
              return item;
            }
          );
          console.log('~~~~~~~~~~', this.selectInspectionTask);
          if (this.detailsData.sysFile.length !== 0) {
            // if (this.requestType === -1) {
              this.pictureUrl = this.detailsData.sysFile[0].url;
              this.previewList.push(this.pictureUrl);
            // }
            if (this.requestType === 1) {
              this.fileList = this.detailsData.sysFile.map((item) => {
                return { url: item.url };
              });
            }
          }
        }
      });
    },
    //获取巡检单
    queryInspectionFormParams() {
      let obj = {
        stationId: this.taskForm.stationId
      }
      inspectionTaskApi.queryInspectionFormParams(obj).then((res) => {
        if (res.code === 200) {
          this.inspectionForm = res.data;
        }
      });
    },
    updateTaskStatus(id, status) {
      inspectionTaskApi.updateTaskStatus(id, status).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.$store.dispatch("queryMessage");
          this.submitVisible = false;
          this.statusVisible = false;
          this.completeVisible = false;
          this.queryInspectionTask();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    createInspectionTask() {
      console.log('~~~~~~~~~~',this.taskForm);
      if (this.file.length !== 0) {
        this.taskForm.file = this.file[0];
      }
      let formData = new FormData();
      for (let key in this.taskForm) {
        formData.append(key, this.taskForm[key]);
      }
      inspectionTaskApi.createInspectionTask(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.$store.dispatch("queryMessage");
          this.submitVisible = false;
          this.dialogVisible = false;
          this.queryInspectionTask();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updateInspectionTask() {
      this.taskForm.id = this.id;
      if (this.file.length !== 0) {
        this.taskForm.file = this.file[0];
      }
      let formData = new FormData();
      for (let key in this.taskForm) {
        formData.append(key, this.taskForm[key]);
      }
      inspectionTaskApi.updateInspectionTask(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.submitVisible = false;
          this.dialogVisible = false;
          this.queryInspectionTask();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    deleteInspectionTask() {
      inspectionTaskApi.deleteInspectionTask(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryInspectionTask();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  watch: {
    "taskForm.stationId": {
      // 执行方法
      handler(newValue, oldValue) {
        if (newValue) {
          this.stationId = newValue;
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
  computed: {
    pickerOptionsEnd() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.taskForm.planStartTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.startAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#inspection-task {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

.form .query-date :deep() .el-form-item__label {
  width: 93px;
}

/* 弹窗 */
.dialog-content {
  margin: 20px 41px 0 41px;
}

.complete-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.details-title {
  font-weight: 700;
  color: #0096ff;
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.square {
  width: 5px;
  height: 5px;
  background-color: #0096ff;
  margin-right: 4px;
}

.interval {
  width: 100%;
  height: 2px;
  background-color: #082657;
  margin-bottom: 16px;
}

/*  */
.select-title {
  width: 720px;
  height: 40px;
  /* border: 1px solid #09295b; */
  background-color: #0b2e60;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
}

.select-title span {
  font-family: "Arial", sans-serif;
  color: #c9d4f1;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #0096ff;
  line-height: 40px;
  display: inline-block;
  margin-right: 3px;
}

.select-content {
  padding: 0 16px;
  background-color: #041c4c;
  border-top: 2px solid #0b2e60;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  box-sizing: border-box;
}

.select-content span {
  color: #0096ff;
  font-size: 12px;
}

.selected-inspection {
  background-color: #0b2e60;
}

.no-data {
  height: 60px;
  color: #c9d4f1;
  font-size: 12px;
  line-height: 60px;
  text-align: center;
  background-color: #041c4c;
  margin: 16px 0;
}

.dialog-form :deep() .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.dialog-form :deep() .special .el-form-item__label {
  width: 87px;
  margin-right: 6px;
}

.dialog-form :deep() .el-form-item {
  margin: 0;
  margin-bottom: 16px;
}

.dialog-form :deep() .el-form-item__label {
  width: 93px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
}

/* input */
.dialog-form :deep() .el-input {
  width: 250px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form .short-form :deep() .el-form-item__label {}

.dialog-form .short-form :deep() .el-input {
  width: 120px;
}

.dialog-form :deep() .block .el-input__inner {
  width: 566px;
}

.form-remark {
  width: 575px;
  background-color: #061b45;
  border: 1px solid #09295b;
  box-sizing: border-box;
}

.dialog-form :deep() .el-textarea textarea {
  width: 600px;
  background-color: #061b45;
  border: 1px solid #09295b;
  /* border: 0; */
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .el-upload--picture-card {
  margin: 0 15px 15px 15px;
  width: 60px;
  height: 60px;
  background: #143168;
  border: 1px solid #143168;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.el-upload-tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 8px;
}

/* 时间选择器 */
/*时间日期选择器默认样式修改 */
.dialog-form :deep() .el-picker-panel .el-input {
  width: 100%;
}

::v-deep .el-time-panel {
  background: #041c4c;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409eff;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}

::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041c4c;
}

::v-deep .is-plain:hover {
  background: #176ad2;
  border: 0;
  color: #fff;
}

/* 巡检结果 */
.inspection-result {
  /* height: 120px; */
  margin: 0 -8px;
  position: relative;
}

.dialog-form :deep() .inspection-result .el-form-item__label {
  width: 69px;
}

.dialog-form :deep() .inspection-result .el-textarea textarea {
  width: 559px;
}

.abnormal-button {
  position: absolute;
  right: 14px;
  bottom: 16px;
}

.length1 {
  width: 600px !important;
}
.dialog-text{
  text-align: center;
  margin: 50px 0;
}
::v-deep .el-input__inner{
    height: 40px !important;
  }
</style>
